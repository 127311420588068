// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom Toast Styles */
.Toastify__toast {
    background-color: rgba(0, 0, 0, 0.8) !important;
    font-size: 12px;
    padding: 0 10px !important;
    margin: 5px auto !important;
    min-height: 25px !important;
    height: 32px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    border-radius: 10px;
}

.Toastify__close-button {
    display: flex;
    align-items: center;
    align-self: unset !important;
}

.Toastify__toast-icon {
    width: 15px;
}

.center-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    color: #fff;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,+CAA+C;IAC/C,eAAe;IACf,0BAA0B;IAC1B,2BAA2B;IAC3B,2BAA2B;IAC3B,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,WAAW;IACX,kBAAkB;AACtB","sourcesContent":["/* Custom Toast Styles */\n.Toastify__toast {\n    background-color: rgba(0, 0, 0, 0.8) !important;\n    font-size: 12px;\n    padding: 0 10px !important;\n    margin: 5px auto !important;\n    min-height: 25px !important;\n    height: 32px !important;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 80%;\n    border-radius: 10px;\n}\n\n.Toastify__close-button {\n    display: flex;\n    align-items: center;\n    align-self: unset !important;\n}\n\n.Toastify__toast-icon {\n    width: 15px;\n}\n\n.center-message {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    font-size: 24px;\n    color: #fff;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
