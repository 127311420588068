// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats, .boost .header {
    text-align: center;
    color: white;
}

.stats-info .coin-text {
    font-size: 3rem;
    font-weight: bold;
    align-content: center;
}

.stats .header .title, .boost .header .title {
    display: inline-block;
    font-size: 15px;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-weight: bold;
    opacity: 0.7;
}

.stats .total-count, .boost .total-count {
    font-size: 3rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.stats .count {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.stats .title {
    display: inline-block;
    font-size: 15px;
    margin-bottom: 0.5rem;
    font-weight: bold;
    opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/pages/Stats.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,qBAAqB;IACrB,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":[".stats, .boost .header {\n    text-align: center;\n    color: white;\n}\n\n.stats-info .coin-text {\n    font-size: 3rem;\n    font-weight: bold;\n    align-content: center;\n}\n\n.stats .header .title, .boost .header .title {\n    display: inline-block;\n    font-size: 15px;\n    margin-top: 3rem;\n    margin-bottom: 1rem;\n    font-weight: bold;\n    opacity: 0.7;\n}\n\n.stats .total-count, .boost .total-count {\n    font-size: 3rem;\n    font-weight: bold;\n    display: flex;\n    justify-content: center;\n    margin-bottom: 2rem;\n}\n\n.stats .count {\n    font-size: 1.5rem;\n    font-weight: bold;\n    display: flex;\n    justify-content: center;\n    margin-bottom: 1rem;\n}\n\n.stats .title {\n    display: inline-block;\n    font-size: 15px;\n    margin-bottom: 0.5rem;\n    font-weight: bold;\n    opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
