// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper p {
    width: 90%;
    margin: 1rem auto;
}

.swiper .subtitle {
    opacity: 0.7;
}

.swiper .progress-bar {
    position: unset !important;
    width: 90%;
}


/* Swiper navigation buttons */
.swiper-button-next, .swiper-button-prev {
    color: #fff;
    padding: 0 20px;
}

.league-swiper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.league .swiper {
    text-align: center;
    color: #fff;
    margin-top: 3rem;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev,.swiper-button-prev::after, .swiper-button-next::after {
    font-size: 20px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/League.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,UAAU;AACd;;;AAGA,8BAA8B;AAC9B;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".swiper p {\n    width: 90%;\n    margin: 1rem auto;\n}\n\n.swiper .subtitle {\n    opacity: 0.7;\n}\n\n.swiper .progress-bar {\n    position: unset !important;\n    width: 90%;\n}\n\n\n/* Swiper navigation buttons */\n.swiper-button-next, .swiper-button-prev {\n    color: #fff;\n    padding: 0 20px;\n}\n\n.league-swiper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n\n.swiper-container {\n    width: 100%;\n    height: 100%;\n}\n\n.league .swiper {\n    text-align: center;\n    color: #fff;\n    margin-top: 3rem;\n}\n\n.swiper-button-next, .swiper-rtl .swiper-button-prev,.swiper-button-prev::after, .swiper-button-next::after {\n    font-size: 20px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
