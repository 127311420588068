import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { TonConnectButton, useTonConnectModal } from "@tonconnect/ui-react";
import './Tap.css';
import Loading from '../components/Loading';

const Tap = () => {
    const userId = localStorage.getItem("userId");
    const [energy, setEnergy] = useState(0);
    const [coins, setCoins] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [earnedCoins, setEarnedCoins] = useState(0);
    const [username, setUsername] = useState("Unknown");
    const [avatar, setAvatar] = useState("");
    const [level, setLevel] = useState("No rank");
    const [bot, setBot] = useState(false);
    const [energyLevel, setEnergyLevel] = useState(1);
    const [tapLevel, setTapLevel] = useState(1);
    const [speedLevel, setSpeedLevel] = useState(1);
    const [lastTapUse, setLastTapUse] = useState(null);
    const [loading, setLoading] = useState(true);
    const { state, open, close } = useTonConnectModal();
    const [plusOnes, setPlusOnes] = useState([]);
    const [lastTap, setLastTap] = useState(null);
    const [turbo, setTurbo] = useState(false);
    const updateTimeoutRef = useRef(null); // useRef to store the timeout ID

    useEffect(() => {
        const turboStatus = JSON.parse(sessionStorage.getItem('turbo'));
        const turboActivationTime = JSON.parse(sessionStorage.getItem('turboActivationTime'));

        if (turboStatus && turboActivationTime) {
            const timeElapsed = Date.now() - turboActivationTime;
            if (timeElapsed < 20000) {
                setTurbo(true);
                const remainingTime = 20000 - timeElapsed;

                // Set timeout to clear turbo mode after the remaining time
                const turboTimeout = setTimeout(() => {
                    setTurbo(false);
                    sessionStorage.removeItem('turbo');
                    sessionStorage.removeItem('turboActivationTime');
                }, remainingTime);

                return () => clearTimeout(turboTimeout);
            } else {
                setTurbo(false);
                sessionStorage.removeItem('turbo');
                sessionStorage.removeItem('turboActivationTime');
            }
        }
    }, []);

    useEffect(() => {
        if (turbo) {
            const turboTimeout = setTimeout(() => {
                setTurbo(false);
                sessionStorage.removeItem('turbo');
                sessionStorage.removeItem('turboActivationTime');
            }, 20000);

            return () => clearTimeout(turboTimeout);
        }
    }, [turbo]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.BackButton.hide();
            }
            try {
                const response = await axios.get(`/api/users/${userId}`);
                if (response.data) {
                    const maxEnergy = response.data.energyLevel * 500;
                    const elapsedTime = Math.floor((Date.now() - new Date(response.data.lastTapUse).getTime()) / 1000);
                    const effectiveElapsedTime = elapsedTime * speedLevel;
                    const restoredEnergy = Math.min(effectiveElapsedTime, maxEnergy);

                    setEnergy(Math.min(response.data.energy + restoredEnergy, maxEnergy));
                    setCoins(response.data.coins);
                    setUsername(response.data.username != null ? response.data.username : response.data.first_name);
                    setLevel(response.data.level);
                    setBot(response.data.bot);
                    setEnergyLevel(response.data.energyLevel);
                    setTapLevel(response.data.tapLevel);
                    setSpeedLevel(response.data.speedLevel);
                    setLastTapUse(response.data.lastTapUse);

                    if (response.data.bot) {
                        handleBotCoinsUpdate(response.data.lastTapUse, response.data.tapLevel, response.data.energy, maxEnergy);
                    }
                }

                const resAvatar = await axios.get(`/api/users/avatar/${userId}`, {
                    responseType: 'blob',
                });
                if (resAvatar.data) {
                    const objectUrl = URL.createObjectURL(resAvatar.data);
                    setAvatar(objectUrl);
                }
            } catch (error) {
                console.error('Failed to fetch user data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId, tapLevel, speedLevel]);

    useEffect(() => {
        if (bot) {
            if (earnedCoins > 1000) {
                setIsModalOpen(true);
            }
        }
    }, [bot, earnedCoins]);

    const handleBotCoinsUpdate = async (lastTapUse, tapLevel, initialEnergy, maxEnergy) => {
        try {
            // Fetch current server time
            const response = await axios.get('/api/users/server-time');

            const serverTime = new Date(response.data.serverTime).getTime();  // Server time in milliseconds
            const lastTime = new Date(lastTapUse).getTime();
            const elapsedSeconds = Math.floor((serverTime - lastTime) / 1000);
            const maxSeconds = 12 * 60 * 60; // 12 hours in seconds

            // Calculate the energy refill time in seconds, adjusted by speedLevel
            const energyFillTime = (maxEnergy - initialEnergy) / speedLevel;

            // Ensure we do not calculate beyond 12 hours
            const cappedElapsedSeconds = Math.min(elapsedSeconds, maxSeconds);

            let secondsToCount = 0;
            if (cappedElapsedSeconds > energyFillTime) {
                secondsToCount = cappedElapsedSeconds - energyFillTime;
            }

            const earnedCoins = secondsToCount * speedLevel;

            setEarnedCoins(Math.round(earnedCoins));
            setCoins(prevCoins => prevCoins + earnedCoins);
            setLastTapUse(serverTime); // Update last tap time with server time

        } catch (error) {
            console.error('Failed to update bot coins', error);
        }
    };


    const handleGetBotCoins = async () => {
        closeModal();
        setCoins(prevCoins => prevCoins + earnedCoins);
        try {
            await axios.put('/api/users', {userId, coins: coins + earnedCoins});
            console.log('User data updated successfully');
        } catch (error) {
            console.error('Failed to update user data', error);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const maxEnergy = energyLevel * 500;
            setEnergy(prevEnergy => Math.min(prevEnergy + speedLevel, maxEnergy));
        }, 1000);

        return () => clearInterval(interval);
    }, [speedLevel, energyLevel]);

    const handleTap = (event) => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        }
        if (energy > 0) {
            const coin = event.currentTarget;
            const rect = coin.getBoundingClientRect();
            const touches = Array.from(event.touches);

            if (turbo) {
                setCoins(prevCoins => (prevCoins + (tapLevel * 5)));
            } else {
                setEnergy(prevEnergy => Math.max(0, prevEnergy - tapLevel));
                setCoins(prevCoins => prevCoins + tapLevel);
            }

            const newPlusOnes = touches.map(touch => {
                const x = touch.clientX - rect.left;
                const y = touch.clientY - rect.top;
                return { id: Date.now() + Math.random(), x, y };
            });

            setPlusOnes(prev => [...prev, ...newPlusOnes]);
            setLastTap(Date.now());

            if (updateTimeoutRef.current) {
                clearTimeout(updateTimeoutRef.current);
            }
            updateTimeoutRef.current = setTimeout(saveTapData, 1000); // Schedule a new update 1 second after the last tap
        }
    };

    const saveTapData = async () => {
        try {
            await axios.put('/api/users', { userId, coins, energy });
            console.log('User data updated successfully');
        } catch (error) {
            console.error('Failed to update user data', error);
        }
    };

    const closeModal = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsModalOpen(false);
            setIsClosing(false);
        }, 300);
    };

    const formatNumber = (number) => {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(3) + ' B'; // Convert to billions and add 'T'
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(3) + ' M';
        }
        return new Intl.NumberFormat('en-US').format(Math.round(number)); // Format with thousand separators
    };

    return (
        <div className={`game ${turbo ? 'turbo-mode' : ''}`}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="squad-container">
                        <a href="/League">
                            <div className="login">
                                <div className="login-detail">
                                    <div className="profile">
                                        {avatar ? (
                                            <img width="50" src={avatar} alt="Avatar"/>
                                        ) : (
                                            <span>?</span>
                                        )}
                                    </div>
                                    <div className="user-data">
                                        <span className="name">{username}</span>
                                        <span className="user-rank">
                                        <img src={`/icons/leagues/${level.toLowerCase()}.png`} alt="Leages" className="select-none"
                                             width="23px"/>
                                            {level}
                                    </span>
                                    </div>
                                </div>
                                <div>

                                    <svg
                                        width="30px" height="30px" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19"
                                            stroke="#ffffff" opacity="0.4" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>

                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="coin-count-container">
                        <img src="/icons/coin.png" width="45px" alt='Coin'/>
                        <h1 className="select-none">{Math.round(coins).toLocaleString('en-US')}</h1>
                    </div>

                    <div className={`coin-container ${turbo ? 'turbo-coin' : ''}`}>
                        <img
                            src="/icons/golden.png"
                            id="coin"
                            alt="Coin"
                            width="250px"
                            onTouchStart={handleTap}
                        />
                        {plusOnes.map(plusOne => (
                            <div
                                key={plusOne.id}
                                className="plus-one"
                                style={{left: `${plusOne.x}px`, top: `${plusOne.y}px`}}
                            >+{turbo ? tapLevel * 5 : tapLevel}</div>
                        ))}
                    </div>

                    <div className="menu-container">
                        <div className="power">
                            <img src="/icons/thunder.png" alt="" width="40px"/>
                            <div className="power-count">
                                <span id="power" className="select-none">{energy}</span>
                                <span className="gray select-none" id="total">/{energyLevel * 500}</span>
                            </div>
                        </div>

                        <TonConnectButton className="menu-bottuns"/>
                    </div>

                    <div className="progress-bar">
                        <div className="progress" style={{width: `${(energy / (energyLevel * 500)) * 100}%`}}></div>
                    </div>

                    {isModalOpen && (
                        <div className="modal">
                            <div className="modal-content">
                                <button className="close-button" onClick={closeModal}>×</button>
                                <img src="/icons/robot_face.png" alt="Tap Bot" className="modal-image"/>
                                <h2>Tap Bot</h2>
                                <p>While you were asleep, your Tap Bot earned some Shares for you ❤️</p>
                                <div className="modal-fee">
                                    <img src="/icons/coin.png" width="25px" alt="Coin"/>
                                    <span>{earnedCoins.toLocaleString('en-US')}</span>
                                </div>
                                <button onClick={handleGetBotCoins} className="modal-button">
                                    Get it!
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Tap;
