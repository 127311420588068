// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard .frens-data {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.leaderboard .frens-help,.leaderboard .frens-list {
    padding: 1rem;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Leaderboard.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,WAAW;AACf","sourcesContent":[".leaderboard .frens-data {\n    border-bottom: 1px solid rgba(0, 0, 0, 0.3);\n}\n\n.leaderboard .frens-help,.leaderboard .frens-list {\n    padding: 1rem;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
