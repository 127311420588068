// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 20px;
    right: 0;
    left: 0;
    width: 90%;
    margin: 0 auto;
    /*box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);*/
    z-index: 100;
    background: none !important;
}

.nav-item {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 0.9rem;
    padding: 5px 0px;
    border-radius: 12px;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    margin: 5px 5px;
    border: 2px solid rgba(23, 22, 21, 0.2);
}

.nav-item img {
    width: 30px;
    height: 30px;
}

.nav-item.active {
    color: #ffcc00;
    border: 2px solid rgba(255, 204, 0, 0.6);
    background-color: rgba(255, 204, 0, 0.1);
}

.nav-item span {
    margin-top: 0.3rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,OAAO;IACP,UAAU;IACV,cAAc;IACd,6CAA6C;IAC7C,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,8DAA8D;IAC9D,eAAe;IACf,uCAAuC;AAC3C;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,wCAAwC;IACxC,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".navbar {\n    display: flex;\n    justify-content: space-between;\n    position: fixed;\n    bottom: 20px;\n    right: 0;\n    left: 0;\n    width: 90%;\n    margin: 0 auto;\n    /*box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);*/\n    z-index: 100;\n    background: none !important;\n}\n\n.nav-item {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-decoration: none;\n    color: white;\n    font-size: 0.9rem;\n    padding: 5px 0px;\n    border-radius: 12px;\n    transition: border-color 0.3s ease, background-color 0.3s ease;\n    margin: 5px 5px;\n    border: 2px solid rgba(23, 22, 21, 0.2);\n}\n\n.nav-item img {\n    width: 30px;\n    height: 30px;\n}\n\n.nav-item.active {\n    color: #ffcc00;\n    border: 2px solid rgba(255, 204, 0, 0.6);\n    background-color: rgba(255, 204, 0, 0.1);\n}\n\n.nav-item span {\n    margin-top: 0.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
