import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Tap from './pages/Tap';
import Task from './pages/Task';
import TaskDetail from './pages/TaskDetail';
import Ref from './pages/Ref';
import Boost from './pages/Boost';
import Leaderboard from './pages/Leaderboard';
import Stats from './pages/Stats';
import Navbar from './components/Navbar';
import League from "./pages/League";
import "./components/css/style.css";
import "./components/css/responsive.css";
import {ToastContainer, Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
// import eruda from 'eruda';

axios.defaults.baseURL = 'https://api.pandaxbot.info';

const App = () => {
    // eruda.init();
    const [userId, setUserId] = useState(null);

    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.MainButton.hide();
    }

    useEffect(() => {
        // Telegram Web Apps SDK to get the initial data
        if (window.Telegram && window.Telegram.WebApp.initDataUnsafe) {
            window.Telegram.WebApp.setHeaderColor("#000000");
            window.Telegram.WebApp.disableVerticalSwipes()

            const initData = window.Telegram.WebApp.initDataUnsafe;
            if (initData && initData.user && initData.user.id) {
                setUserId(initData.user.id);
                localStorage.setItem("userId", initData.user.id);
            }
        }

        const handleTouchMove = (e) => {
            if (!e.target.closest('.allow-touch')) {
                e.preventDefault();
            }
        };

        document.addEventListener('touchmove', handleTouchMove, {passive: false});
    }, []);

    return (
        <>
            {/*{userId ? (*/}
                <Router>
                    <div className="app">
                        <ToastContainer
                            position="top-center"
                            autoClose={2000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick={true}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable
                            pauseOnHover
                            theme="dark"
                            transition={Slide}/>

                        <Routes>
                            <Route path="/" element={<Tap/>}/>
                            <Route path="/task" element={<Task/>}/>
                            <Route path="/tasks/:taskId" element={<TaskDetail/>}/>
                            <Route path="/ref" element={<Ref/>}/>
                            <Route path="/boost" element={<Boost/>}/>
                            <Route path="/stats" element={<Stats/>}/>
                            <Route path="/leaderboard" element={<Leaderboard/>}/>
                            <Route path="/league" element={<League/>}/>
                        </Routes>

                    </div>
                    <footer className="footer">
                        <Navbar/>
                    </footer>
                </Router>
            {/*) : (*/}
            {/*    <div className="center-message">*/}
            {/*        Open the app from Telegram*/}
            {/*    </div>*/}
            {/*)}*/}
        </>
    );
};

export default App;
