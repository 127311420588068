import React from 'react';
import ReactDOM from 'react-dom';
import {THEME, TonConnectUIProvider} from "@tonconnect/ui-react";
import './index.css';
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <TonConnectUIProvider
            manifestUrl="https://arabcoin.net/tonconnect-manifest.json"
            uiPreferences={{theme: THEME.DARK}}
            actionsConfiguration={{
                twaReturnUrl: 'https://t.me/arab_coin_bot/start'
            }}
        >
            <App/>
        </TonConnectUIProvider>
    </React.StrictMode>,
    document.getElementById('root') // Ensure this matches the id in index.html
);
