// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 764px) {
    body,
    .boost, 
    .guid,
    .frens,
    .earn,
    .login-page,
    .navbar {
        max-width: 450px;
        margin: 0 auto;
        background-color: #191919;
        background-size: 450px;
        background-position: center;
    }
    .squad-container {
        margin: 0.5rem auto;
    }
    .slide  {
        width: 19.5rem!important;
    }
    .frens-invite-button {
        width: 26.5rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/css/responsive.css"],"names":[],"mappings":"AAAA;IACI;;;;;;;QAOI,gBAAgB;QAChB,cAAc;QACd,yBAAyB;QACzB,sBAAsB;QACtB,2BAA2B;IAC/B;IACA;QACI,mBAAmB;IACvB;IACA;QACI,wBAAwB;IAC5B;IACA;QACI,cAAc;IAClB;AACJ","sourcesContent":["@media screen and (min-width: 764px) {\n    body,\n    .boost, \n    .guid,\n    .frens,\n    .earn,\n    .login-page,\n    .navbar {\n        max-width: 450px;\n        margin: 0 auto;\n        background-color: #191919;\n        background-size: 450px;\n        background-position: center;\n    }\n    .squad-container {\n        margin: 0.5rem auto;\n    }\n    .slide  {\n        width: 19.5rem!important;\n    }\n    .frens-invite-button {\n        width: 26.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
